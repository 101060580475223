import { useSelector } from 'react-redux';

const useGlobalSettings = () => {
    const template_settings = useSelector(state => state.globalReducer.template_settings);

    const getSetting = (key, default_flag = true) => {
        return template_settings?.[key] || default_flag
    }

    return {getSetting}
}

export default useGlobalSettings;